import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Item = props => {
  const { theme, item: { label, to, icon: Icon } = {}, onClick } = props;

  return (
    <React.Fragment>
      <li className={"hiddenItem" in props ? "hiddenItem" : "item"} key={label}>
        <Link
          to={to}
          className={"hiddenItem" in props ? "inHiddenItem" : ""}
          onClick={onClick}
          data-slug={to}
        >
          {Icon && <Icon />} {label}
        </Link>
      </li>

      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .item,
        .showItem {
          display: inline-block;
          float: left;
          position: relative;

          :first-child:before {
            content: "";
            display: none;
          }

          :before {
            font-family: "arial", sans-serif;
            font-size: 8px;
            content: "•";
            vertical-align: 3px;
             color: ${theme.colour.grey.lightest};
            padding-left: 0;
          }
        }

        :global(.itemList .hideItem) {
          display: none;
        }

        @from-width desktop {
          .item {
            :global(a) {
              cursor: pointer;
              text-transform: uppercase;
              font-family: "Montserrat", "Arial", sans-serif;
              font-size: 14px;
              padding: 3px 12px 15px;
              color: #4b4b4b;
              transition: all ${theme.time.duration.default};
            }

            :global(.hero):not(.fixed) & :global(a) {
              color: #d9d9d9;
            }

            :global(a:hover) {
              color: #0f0f0f !important;
            }

            :global(.hero):not(.fixed) & :global(a:hover) {
              color: #fff !important;
            }

            :global(svg) {
              transition: all ${theme.time.duration.default};
            }

            &:hover :global(svg) {
              fill: ${theme.color.brand.primary};
              opacity: 1;

              :global(.hero) & :global(svg) {
                fill: green;
              }
            }
          }

          .showItem {
            display: none;
          }

          .hiddenItem {
            text-align: left;
            padding: ${theme.space.xs};

            & :global(a.inHiddenItem) {
              color: ${theme.text.color.primary};
              &:hover {
                color: ${theme.color.brand.primary};
              }
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default Item;
