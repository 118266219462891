module.exports = {
  siteTitle: "Peter Reid", // <title>
  homePageTitle: "Peter Reid",
  shortSiteTitle: "Peter Reid", // <title> ending for posts and pages
  siteDescription:
    "Software Engineer from Belfast with experience in Cloud Engineering, Serverless, Node.js, DevOps, and Developer Enablement",
  siteUrl: "https://reidweb.com",
  pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",
  // author
  authorName: "Peter Reid",
  authorTwitterAccount: "reidweb",
  disqusShortName: "reidweb-com",
  // info
  headerTitle: "Peter Reid",
  headerSubTitle: "Software Engineer from Belfast with experience in Node.js, DevOps, Developer Enablement and Cloud Engineering",
  // manifest.json
  manifestName: "Peter Reid",
  manifestShortName: "Peter Reid", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#242526",
  manifestDisplay: "standalone",
  menuLinks: [
    { to: "/", label: "Home" },
    { to: "/about/", label: "About" }
  ],
  enabledHeroSocialLinks: [
    "twitter",
    "linkedin",
    "github",
    "stackoverflow",
    "medium",
  ],
  enabledFooterSocialLinks: [
    "twitter",
    "linkedin",
    "github",
    "stackoverflow",
    "medium",
    "keybase",
    "rss"
  ],
  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/ReidWeb" },
    { name: "twitter", url: "https://twitter.com/ReidWeb" },
    { name: "facebook", url: "http://facebook.com/" },
    {
      name: "stackoverflow",
      url: "https://stackoverflow.com/users/2344348/peter-reid?tab=profile"
    },
    { name: "linkedin", url: "https://www.linkedin.com/in/reidweb" },
    { name: "medium", url: "https://medium.com/@reidweb" },
    { name: "keybase", url: "https://keybase.io/peterreid" },
    { name: "rss", url: "/rss.xml" }
  ]
};
