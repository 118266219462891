import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import Text from "../Hero/Text";
import logo from "../../images/logo/black.svg";
import Menu from "../Menu";
import Item from "../Menu/Item";
import {FaBars, FaTimes} from "react-icons/fa";
const config = require("../../../content/meta/config");


class Drawer extends React.Component {

  constructor(props) {
    super(props);
  }


  render() {
    const { theme, subtitle, closeHandler, title, titleLink, textColor, items, visible } = this.props;

    return (
      <React.Fragment>
        <section className={visible ? "drawer" : "drawer closeddrawer hidden" }>
          <div className="close-button" ><FaTimes onClick={closeHandler}/></div>
          <section className="drawer-section">
            <a href={titleLink}>
              <img src={logo} className="retina logo" alt="Peter Reid" />
            </a>
            <p>{subtitle}</p>

          </section>
          <section className="drawer-section">
            <nav className={`menu`} rel="js-menu">
              <ul className="drawer-menu" ref={React.createRef()}>
            <span className="menu-links">
              {items.map(item => (
                <li className={"drawer-menu-item"} key={item.label}>
                  <Link
                    to={item.to}
                    data-slug={item.to}
                  > {item.label}
                  </Link>
                </li>
              ))}
            </span>
              </ul>
            </nav>
          </section>
        </section>

        {/* --- STYLES --- */}
        {/*language=SCSS*/}
        <style jsx>{`

        .close-button {
            display: none;
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;
        }
        .hidden {
           display: none;
        }
        .drawer-section {
          padding: 35px 0 0;
          .drawer-menu {
            list-style: none;
            margin: 0;
            position: relative;
            width: 100%;
          }


          .drawer-menu-item {
            padding: 15px 0;

            :global(a) {
              cursor: pointer;
              text-transform: uppercase;
              font-family: "Montserrat", "Arial", sans-serif;
              font-size: 14px;
              padding: 3px 12px 15px;
              color: #4b4b4b;
              transition: all ${theme.time.duration.default};
            }
          }


          p {
             color: ${theme.color.neutral.gray.h};
             line-height: ${theme.font.lineHeight.xxxl};
             font-size: ${theme.font.size.xxs};
             font-family: ${theme.font.reidweb.subheader};
             margin-block-start: 1em;
          }

          ::after {
            display: block;
            clear: both;
            content: '';
            width: 20%;
            background: ${theme.color.neutral.gray.c};
            height: 1px;
            margin-top: 35px;
          }
        }

        .logo {
            max-width: 80%;
            :after {
              display: block;
              content: "";
              clear: both;
              width: 15%;
              background: ${theme.colour.grey.medium};
              margin: 25px auto 0 auto;
            }
          }


        .drawer {
          position: fixed;
          z-index: 3000;
          width: 400px;
          height: 100%;
          top: 0;
          right: 0;
          background: #ffffff;
          overflow-y: auto;
          border-left: 1px solid #eaeaea;
          padding: 0 35px;
          -webkit-overflow-scrolling: touch;
          @include transform(translateX(100%));
          @include transition(transform 500ms ease-in-out);

          &.show {
            @include transform(translateX(0%));
          }

          .closedrawer {
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;
            cursor: pointer;
          }

          @media (max-width: 420px) {
            width: 325px;
          }
        }

        @below tablet {
        .close-button {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            padding: 20px;
        }
        }
      `}</style>
      </React.Fragment>
    );

  }
}


Drawer.propTypes = {
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  titleLink: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired
};

export default Drawer;
