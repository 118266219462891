import React from "react";
import PropTypes from "prop-types";
import Meta from "./Meta";

const Text = props => {
  const { theme, logo, title, titleLink, subtitle, color, tags, isTag } = props;

  const underlineHeight = isTag ? "0" : "4px";

  return (
    <React.Fragment>
      <section className="blogtitle wrapper">
        {tags && tags.length > 0 ? <Meta theme={theme} tags={tags} /> : null}
        {titleLink !== undefined && logo !== undefined ? (
          <a href={titleLink} className="logo">
            <img src={logo} className="retina" alt="Peter Reid" />
          </a>
        ) : null}
        {titleLink !== undefined && logo === undefined ? (
          <a href={titleLink} className="logo">
            <h1>{title}</h1>
          </a>
        ) : null}
        {titleLink === undefined && logo !== undefined ? (
          <img src={logo} className="retina logo" alt="Peter Reid" />
        ) : null}
        {titleLink === undefined && logo === undefined ? <h1 className="logo">{title}</h1> : null}
        <p className="description" itemProp="description">
          {subtitle}
        </p>
      </section>
      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .blogtitle,
        .errortitle {
          display: table-cell;
          vertical-align: middle;
          width: 700px;
          position: relative;
          z-index: 1;
          text-align: center;

          h1 {
            font-size: 48px;
            color: ${color};
            font-family: "Varela Round", "Arial", Sans-Serif;
            font-weight: 600;
            line-height: 62px;
            letter-spacing: -1px;
            margin-top: 25px;
          }

          .logo {
            :after {
              display: block;
              content: "";
              clear: both;
              height: ${underlineHeight};
              width: 15%;
              background: ${theme.colour.grey.medium};
              margin: 25px auto 0 auto;
            }
          }

          .description {
            font-family: "Varela Round", "Arial", Sans-Serif;
            text-align: center;
            font-size: 23px;
            line-height: 38px;
            color: ${theme.colour.grey.light};
            letter-spacing: -1px;
            margin: 40px 0 35px;
          }

          .wrapper {
            max-width: 700px;
            width: 90%;
            margin: 0 auto;
          }

          @media (max-width: 880px) {
            width: 70%;
            text-align: center;

            .description {
              font-size: 20px;
              line-height: 32px;
            }

            hr {
              margin: 0 auto;
              width: 20%;
            }
          }

          @media (max-height: 520px) {
            .description,
            hr {
              display: none;
            }
          }

          @media (max-width: 540px) {
            width: 80%;

            img {
              margin-top: 25%;
            }

            .description {
              font-size: 18px;
              line-height: 28px;
              margin-top: 25px;
            }

            hr {
              display: none;
            }

            .logo {
              img {
                flex-grow: 1;
                width: 100%;
                height: 100%;
              }
            }

            vertical-align: bottom;
            .logo:after {
              display: none;
              height: 0;
            }

            h1,
            h2 {
              font-size: 32px;
              line-height: 38px;
              width: 85%;
              margin: 10% 7.5% 10%;
            }

            .meta .category {
              font-size: 10px;
              margin: 0 auto;
              float: none;
            }

            .description {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }

        @from-width tablet {
        }

        @from-width desktop {
        }
      `}</style>
    </React.Fragment>
  );
};

Text.propTypes = {
  theme: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  titleLink: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  logo: PropTypes.string,
  color: PropTypes.string.isRequired,
  tags: PropTypes.array,
  isTag: PropTypes.bool
};

export default Text;
