import { graphql, Link, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import VisibilitySensor from "react-visibility-sensor";

import { FontLoadedContext, ScreenWidthContext } from "../../layouts";
import Menu from "../Menu";

import { FaBars } from "react-icons/fa/";
import Logo from "../Logo/Logo";

class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      fixed: false,
      makeHeaderLogoDark: false
    };
  }

  visibilitySensorChange = val => {
    if (val) {
      this.setState({ fixed: false, makeHeaderLogoDark: true });
    } else {
      this.setState({ fixed: true, makeHeaderLogoDark: false });
    }
  };

  getHeaderSize = thisPageHasNoHero => {
    const fixed = this.state.fixed ? "fixed" : "";
    const hero = thisPageHasNoHero ? "fixed" : "hero";

    return `${fixed} ${hero}`;
  };

  render() {
    const { pages, path, theme, drawerToggleFunction, className, drawerOpen } = this.props;
    const { fixed } = this.state;

    return (
      <StaticQuery
        // Query to lookup pages where no cover is set
        query={graphql`
          query HeaderQuery {
            pages: allMarkdownRemark(filter: {frontmatter: {cover: {name: {eq: null}}}}) {
              edges {
                node {
                  fields {
                    slug
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const {
            pages: { edges: pagesWithNoHeroRes }
          } = data;
          //Create simple array of pages that have no hero
          let pagesWithNoHero = pagesWithNoHeroRes.map(obj => obj.node.fields.slug?.replace("/",""));
          // Remove nulls
          pagesWithNoHero = pagesWithNoHero.filter(obj => {
            return obj;
          });
          const thisPageHasNoHero = pagesWithNoHero.includes(this.props.path.replace("/", ""));

          return (
            <React.Fragment>
              <header className={`header ${this.getHeaderSize(thisPageHasNoHero)} ${className}`}>
                <Link to="/" className="logoType" aria-label="Logo - Navigate to root">
                  <div className="reidweb-logo">
                    <Logo path={this.props.path} width={"135px"} height={"31px"} fill={this.state.makeHeaderLogoDark || thisPageHasNoHero ? "#000" : "#fff"}/>
                  </div>
                </Link>
                <FontLoadedContext.Consumer>
                  {loaded => (
                    <ScreenWidthContext.Consumer>
                      {width => (
                        <Menu
                          path={path}
                          fixed={fixed}
                          drawerOpen={drawerOpen}
                          screenWidth={width}
                          fontLoaded={loaded}
                          pages={pages}
                          theme={theme}
                          drawerToggleFunction={drawerToggleFunction}
                        />
                      )}
                    </ScreenWidthContext.Consumer>
                  )}
                </FontLoadedContext.Consumer>
              </header>
              <VisibilitySensor onChange={this.visibilitySensorChange}>
                <div className="sensor" />
              </VisibilitySensor>

              {/* --- STYLES --- */}
              {/*language=SCSS*/}
              <style jsx>{`
              .page-wrapper {

                        position: relative;
                        left: 0;
                        -webkit-transition: left 500ms ease-in-out;
                        -moz-transition: left 500ms ease-in-out;
                        transition: left 500ms ease-in-out;
                        -webkit-transform: translate3d(0, 0, 0);
              }
                      .slide {
                          cursor: pointer;
                          left: -400px !important;
                }
                .header {
                  align-items: center;
                  justify-content: center;
                  background-color: ${theme.colour.white};
                  display: flex;
                  height: ${theme.header.height.default};
                  top: 0;
                  width: 100%;
                  align-items: center;
                  z-index: 10000;
                  padding: 50px 30px;

                  :global(.drawerNav) {
                    font-size: ${theme.font.size.m};
                  }

                  &.hero {
                    position: absolute;
                    background-color: transparent;
                    height: ${theme.header.height.hero};

                    :not(.fixed) {
                      :global(a.logoType),
                      :global(.drawerNav),
                      h1 {
                        color: ${theme.color.neutral.white};
                      }

                      h2 {
                        color: ${theme.color.neutral.gray.d};
                      }
                    }
                  }
                }

                h1 {
                  font-size: ${theme.font.size.m};
                  font-weight: ${theme.font.weight.standard};
                  margin: ${theme.space.stack.xs};
                }

                h2 {
                  font-weight: ${theme.font.weight.standard};
                  font-size: ${theme.font.size.xxs};
                  letter-spacing: 0;
                  margin: 0;
                }

                .reidweb-logo {
                  img {
                    max-height: 31px;
                  }
                  .black-logo {
                    display: none;
                    visibility: hidden;
                    width: auto;
                    height: auto;
                  }
                  .white-logo {
                    visibility: visible;
                    width: auto;
                    height: auto;
                  }
                }

                .enabled-logo {
                  display: block !important;
                  visibility: visible !important;
                  width: auto;
                  height: auto;
                }

                .disabled-logo {
                  display: none !important;
                  visibility: hidden !important;
                  width: auto;
                  height: auto;
                }

                .logo {
                  border-radius: 65% 75%;
                  border: 1px solid #eee;
                  display: inline-block;
                  height: 44px;
                  margin: ${theme.space.inline.default};
                  overflow: hidden;
                  width: 44px;
                  transition: all 0.5s;

                  .hero & {
                    height: 60px;
                    width: 60px;
                  }

                  img {
                    width: 100%;
                  }
                }

                .sensor {
                  display: block;
                  position: absolute;
                  bottom: 0;
                  z-index: 1;
                  left: 0;
                  right: 0;
                  height: 1px;
                  top: ${theme.header.height.hero};
                }


                @from-width tablet {
                  .header {
                    padding: ${theme.space.inset.l};

                    &.hero {
                      height: ${theme.header.height.hero};
                    }
                  }
                }

                @below desktop {
                  .header {
                    align-items: left;
                    justify-content: left;
                    padding-top: 40px;
                    padding-left: 30px;

                    &.fixed {
                      background-color: ${theme.color.neutral.white};
                      border-bottom: 2px solid #f2f2f2;
                      left: 0;
                      padding: 0 ${theme.space.m};
                      position: fixed;
                      top: 0;
                      width: 100%;
                      z-index: 10000;

                      .reidweb-logo {
                        img {
                          max-height: 31px;
                        }
                        .white-logo {
                          visibility: hidden;
                          display: none;
                          width: auto;
                          height: auto;
                        }
                        .black-logo {
                          display: block;
                          visibility: visible;
                          width: auto;
                          height: auto;
                        }
                      }
                      h1 {
                        margin: ${theme.space.stack.xxs};
                      }

                      h2 {
                        display: none;
                      }
                    }

                    .hero {
                      .logo {
                        border: none;
                      }

                      :global(a.logoType),
                      h1 {
                        color: ${theme.color.neutral.white};
                      }
                      h2 {
                        color: ${theme.color.neutral.gray.d};
                      }
                    }
                  }
                }
                @from-width desktop {
                  .header {
                    align-items: center;
                    background-color: ${theme.color.neutral.white};
                    display: flex;
                    position: absolute;
                    top: 0;
                    width: 100%;
                    justify-content: space-between;
                    transition: padding 0.5s;
                    padding-top: 50px;

                    &.fixed {
                      height: ${theme.header.height.fixed};
                      background-color: ${path === "/" ? theme.color.neutral.white : "transparent"};
                      border-bottom: ${path === "/" ? "2px solid #f2f2f2" : "none"};
                      left: 0;
                      padding: 0 ${theme.space.m};
                      position: fixed;
                      top: 0;
                      width: 100%;
                      z-index: 100;

                      .reidweb-logo {
                        img {
                          max-height: 31px;
                        }
                        .white-logo {
                          visibility: hidden;
                          display: none;
                          width: auto;
                          height: auto;
                        }
                        .black-logo {
                          display: block;
                          visibility: visible;
                          width: auto;
                          height: auto;
                        }
                      }
                      h1 {
                        margin: ${theme.space.stack.xxs};
                      }

                      h2 {
                        display: none;
                      }
                    }

                    &.hero:not(.fixed) {
                      :global(a.logoType),
                      :global(.drawerNav),
                      h1 {
                        color: ${theme.color.neutral.white};
                      }
                      h2 {
                        color: ${theme.color.neutral.gray.d};
                      }
                    }
                  }

                  .header :global(a.logoType) {
                    text-align: left;
                    flex-direction: row;
                    flex-shrink: 0;
                    width: auto;
                  }

                  .logo {
                    margin: ${theme.space.inline.default};

                    .fixed & {
                      height: 36px;
                      width: 36px;
                    }

                    .header.hero:not(.fixed) & {
                      border: none;
                    }
                  }

                  h2 {
                    animation-duration: ${theme.time.duration.default};
                    animation-name: h2Entry;
                  }

                  @keyframes h2Entry {
                    from {
                      opacity: 0;
                    }
                    to {
                      opacity: 1;
                    }
                  }
                }
                @media (max-width: 1300px) {
                    .header {
                        &.fixed {
                          background-color: ${theme.color.neutral.white};
                          border-bottom: 2px solid #f2f2f2;
                        }
                    }
                }
              `}</style>
            </React.Fragment>
          );
        }}
      />
    );
  }
}

Header.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  isHero: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  drawerToggleFunction: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired
};

export default Header;
