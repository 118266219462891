import React from "react";
import PropTypes from "prop-types";

const Meta = props => {
  const { theme, tags } = props;

  let tagLink = "/tag/" + tags[0];

  return (
    <React.Fragment>
      <ul className="meta">
        <li className="category">
          <a href={tagLink}>{tags[0]}</a>
        </li>
      </ul>

      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .meta {
          display: inline-block;
          overflow: hidden;
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            display: block;

            &:last-child:before {
              content: "";
            }

            &:nth-child(2) a {
              padding-right: 0;
            }
          }

          .category {
            position: relative;
            float: left;
            display: none;

            a {
              display: inline-block;
              background: ${theme.colour.blue.medium};
              border-radius: 3px;
              padding: 10px 15px;
              font-family: ${theme.font.reidweb.subheader};
              font-size: 12px;
              text-transform: uppercase;
              color: #ffffff;

              :hover {
                background: ${theme.colour.blue.dark};
                color: #ffffff;
              }
            }

            &:first-child {
              display: initial;
            }
          }

        }
      `}</style>
    </React.Fragment>
  );
};

Meta.propTypes = {
  theme: PropTypes.object.isRequired,
  tags: PropTypes.array
};

export default Meta;
